import React, { Component } from 'react'

class Main extends Component {
  render() {
    return(
      <section className="main">Main</section>
    )
  }
}

export default Main;
